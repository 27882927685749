<template>
  <v-layout wrap justify-center id="cf">
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-flex xs12 lg11 pa-sm-4 pa-lg-6>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card class="mx-auto elevation-1 pa-6" align="center" outlined>
            <v-layout wrap>
              <v-flex xs12>
                <v-form ref="form1" v-model="valid1">
                  <v-layout wrap>
                    <v-flex
                      xs12
                      sm
                      md12
                      lg2
                      pb-2
                      pb-md-0
                      class="text-left nsbold"
                      style="font-weight: 700; font-size: 20px"
                      >Room List
                    </v-flex>
                    <v-flex
                      xs12
                      sm3
                      md2
                      lg2
                      pb-2
                      pb-md-0
                      align-self-center
                      class="text-left nsregular"
                    >
                      <v-text-field
                        v-model="keyword"
                        dense
                        class="rounded-xl"
                        label=" Search Room/Floor"
                        outlined
                        hide-details
                        clearable
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      xs12
                      sm4
                      md2
                      lg2
                      px-1
                      pb-2
                      pb-md-0
                      align-self-center
                      class="text-left nsregular"
                    >
                      <v-select
                        :items="HostelList"
                        v-model="hostel"
                        label="Hostel"
                        clearable
                        item-text="hostelName"
                        item-value="_id"
                        class="rounded-xl"
                        hide-details
                        outlined
                        @input="getData()"
                        dense
                      ></v-select>
                    </v-flex>
                    <v-flex
                      xs12
                      sm4
                      md2
                      lg2
                      pb-2
                      
                      pb-md-0
                      align-self-center
                      class="text-left nsregular"
                    >
                      <v-select
                        :items="RoomList"
                        v-model="roomType"
                        label="Type"
                        class="rounded-xl"
                        hide-details
                        outlined
                        clearable
                        @input="getData()"
                        dense
                      ></v-select>
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      md5 lg4
                      pb-0
                      pb-sm-2
                      pb-md-0
                      px-1
                      text-left
                      
                      align-self-center
                    >
                      <!-- <v-card rounded outlined  style="cursor: pointer" color="transparent" @click="adddialogue = true"> -->
                      <v-btn
                        block
                        dark
                        color="#766BC0"
                        class="rounded-lg hidden-sm-and-up"
                        @click="adddialogue = true"
                      >
                        <v-icon x-small>mdi-plus</v-icon>
                        <span
                          class="text-left nsregular"
                          style="font-weight: 400; font-size: 12px"
                          >Add Asset</span
                        ></v-btn
                      >
                      <v-btn
                        
                        dark
                        style="text-transform: none; border-radius: 40px"
                        color="#766BC0"
                        @click="adddialogue = true"
                        class="hidden-xs-only"
                      >
                        <!-- class="rounded-lg hidden-xs-only" -->

                        <v-icon x-small>mdi-plus</v-icon>
                        <span
                          class="text-left nsregular"
                          style="font-weight: 400; font-size: 13px"
                          >Add Single Room</span
                        ></v-btn
                      >
                      <v-btn
                        width="170px"
                        dark
                        style="text-transform: none; border-radius: 40px"
                        color="#766BC0"
                        @click="adddialogue2 = true"
                        class="hidden-xs-only ml-2"
                      >
                        <!-- class="rounded-lg hidden-xs-only" -->

                        <v-icon x-small>mdi-plus</v-icon>
                        <span
                          class="text-left nsregular"
                          style="font-weight: 400; font-size: 13px"
                          >Add Multiple Rooms</span
                        ></v-btn
                      >
                      <!-- </v-card> -->
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
            </v-layout>
            <!---------------------add asset-------------------------->

            <!---------------------------------------------------->
            <!-- <v-layout wrap>
              <v-flex
                xs12
                lg6
                class="text-left nsbold"
                style="font-weight: 700; font-size: 20px"
                >
              </v-flex>
            </v-layout> -->
            <!-- <v-layout py-3>
              <v-flex
                xs2
                class="text-left nsbold"
                style="font-weight: 700; font-size: 18px"
              >
                <span>No.</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Name</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Type</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Description</span>
              </v-flex>
            </v-layout>
            <v-layout
              @="(item, i) in student"
              :key="i"
              py-2
              :style="i % 2 == 0 ? 'background-color:#FAFAFA' : ''"
            >
              <v-flex
                xs2
                class="text-left regular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ i + 1 }}</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ item.name }}</span>
              </v-flex>
              <v-flex xs3 class="text-left nsregular"
                style="font-weight: 400; font-size: 18px">
                <span>{{ item.assetType }}</span>
                </v-flex>
                <v-flex xs3 class="text-left nsregular"
                style="font-weight: 400; font-size: 18px">
                <span>{{ item.description }}</span>
              </v-flex>
              <v-flex xs1 v-if="item">
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(curItem = item), (editdialogue = true)"
                    >mdi-circle-edit-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-flex xs1>
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(dialoge = true), (curId = item._id)"
                    >mdi-delete-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-divider></v-divider>
            </v-layout> -->
<v-layout wrap>
  <v-flex xs12 sm6 md6 lg2 pa-2 v-for="(value, i) in roomCount" :key="i">
    <v-card outlined >
<span>Type:{{value.type}}</span>&nbsp;
<span>Count:{{value.count}}</span>

    </v-card>
  </v-flex>
            <v-flex xs12 v-if="student" pb-4>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">S.No.</th>
                      <th class="text-left">Room.No</th>
                      <th class="text-left">Floor Name</th>
                      <th class="text-left">Floor No.</th>
                      <th class="text-left">Type</th>
                      <th class="text-left">Total Capacity</th>
                      <th class="text-left">Hostel Name</th>
                      <th class="text-left">Edit</th>
                      <th class="text-left">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, i) in student" :key="i">
                      <!-- <td>{{ i + 1 }}</td> -->
                      <td>
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                      <td v-if="value.roomNo">{{ value.roomNo }}</td>
                      <td v-if="value.floorId">{{ value.floorId.floorName }}</td>
                      <td v-if="value.floorId">{{ value.floorId.floorNo }}</td>
                      <td v-if="value.type">{{ value.type }}</td>
                      <td v-if="value.totalCapacity">{{ value.totalCapacity }}</td>
                      <td v-if="value.mainHostelId">{{ value.mainHostelId.hostelName }}</td>
                      <td>
                        <v-icon
                          small
                          style="cursor: pointer"
                          @click="(curItem = value), (editdialogue = true)"
                          >mdi-circle-edit-outline</v-icon
                        >
                      </td>
                      <td>
                        <v-icon
                          small
                          style="cursor: pointer"
                          @click="(dialoge = true), (curId = value._id)"
                          >mdi-delete-outline</v-icon
                        >
                      </td>

                      <!-- <td>{{ value.studentid[0].admissionNo }}</td>
                                <td>{{ value.clothid.clothType.name }}</td>
                                <td>{{formatDate(value.INdate)}}</td> -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <!-- delete -->
      <v-dialog width="400px" v-model="dialoge">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Confirm </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4"
            >Are you sure you want to delete this record?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="dialoge = false"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- edit -->
      <v-form ref="form" v-model="valid">
        <v-dialog width="400px" v-model="editdialogue">
          <v-card width="400px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Edit Item </v-toolbar-title>
            </v-toolbar>
            <!-- {{ curItem}} -->
            <v-layout wrap v-if="curItem">
              <v-flex xs12 px-4 pt-4>
                <v-select
                  :items="HostelList"
                  v-model="curItem.hostelName"
                  label="Hostel"
                  item-text="hostelName"
                  item-value="_id"
                  clearable
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-select>
              </v-flex>
              <v-flex xs12 px-4 py-4>
                <v-select
                  :items="Type"
                  v-model="curItem.type"
                  label="Type"
                  item-text="type"
                  item-value="_id"
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-select>
              </v-flex>
              <v-flex xs12 px-4 pt-4>
                <v-select
                  :items="floorList"
                  v-model="curItem.floorId"
                  label="Type"
                  item-text="floorName"
                  item-value="_id"
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-select>
              </v-flex>
              <v-flex xs12 px-4 py-4>
                <v-text-field
                  v-model="curItem.roomNo"
                  outlined
                  label="Room No"

                  hide-details
                  dense
                ></v-text-field>
              </v-flex>

              <v-flex xs12 px-4 py-4>
                <v-text-field
                  v-model="curItem.totalCapacity"
                  outlined
                  label="Capacity"

                  hide-details
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="editdialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                class="body-2 font-weight-bold"
                outlined
                @click="editCategory()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <!-- add -->
      <v-form ref="form">
        <v-dialog width="400px" v-model="adddialogue">
          <v-card width="400px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Add Item </v-toolbar-title>
            </v-toolbar>

            <v-layout wrap>
              <v-flex xs12 px-4 pt-4>
                <v-select
                  :items="HostelList"
                  v-model="hostel"
                  label="Hostel"
                  item-text="hostelName"
                  item-value="_id"
                  clearable
                  @change="getFloor()"
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-select>
              </v-flex>
              <v-flex xs12 px-4 py-4>
                <v-select
                  :items="Type"
                  v-model="type"
                  label="Type"
                  outlined
                  hide-details
                  dense
                  :rules="[rules.required]"
                ></v-select>
              </v-flex>
              <v-flex xs12 px-4 pt-4>
                <v-select
                  :items="floorList"
                  v-model="floorId"
                  label="Floor Id "
                  item-text="floorName"
                  item-value="_id"
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-select>
                <!-- <v-text-field
                  autofocus
                  label="Floor Id "
                  v-model="floorId"
                  outlined
                  dense
                  hide-details
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field> -->
              </v-flex>
              <v-flex xs12 px-4 py-4>
                <v-text-field
                  v-model="roomNo"
                  dense
                  label="Room Number"
                  outlined
                  hide-details
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-4 py-4>
                <v-text-field
                  v-model="totalCapacity"
                  dense
                  label="Room Capacity"
                  outlined
                  hide-details
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="adddialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#766BC0"
                class="body-2 font-weight-bold"
                outlined
                @click="addCategory()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <v-form ref="form">
        <v-dialog width="400px" v-model="adddialogue2">
          <v-card width="400px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Add Item </v-toolbar-title>
            </v-toolbar>

            <v-layout wrap>
              <v-flex xs12 px-4 pt-4>
                <v-select
                  :items="HostelList"
                  v-model="hostel"
                  label="Hostel"
                  item-text="hostelName"
                  item-value="_id"
                  @change="getFloor()"
                  clearable
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-select>
              </v-flex>
              <v-flex xs12 px-4 py-4>
                <v-select
                  :items="Type"
                  v-model="Type1"
                  label="Type"
                  outlined
                  hide-details
                  dense
                  :rules="[rules.required]"
                ></v-select>
              </v-flex>
              <v-flex xs6 pl-4 pr-2 py-4>
                <v-text-field
                  v-model="rangeFrom"
                  dense
                  label="Starting Room No."
                  outlined
                  hide-details
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pr-4 pl-2 py-4>
                <v-text-field
                  v-model="rangeTo"
                  dense
                  label="Ending Room No."
                  outlined
                  hide-details
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-4 pt-4>
                <v-select
                  :items="floorList"
                  v-model="floorId"
                  label="Floor Name "
                  item-text="floorName"
                  item-value="_id"
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-select>
              </v-flex>
              <v-flex xs12 px-4 py-4>
                <v-text-field
                  v-model="totalCapacity"
                  dense
                  label="Room Capacity"
                  outlined
                  hide-details
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="adddialogue2 = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#766BC0"
                class="body-2 font-weight-bold"
                outlined
                @click="addCategory2()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <v-layout wrap>
        <v-flex xs12 pt-4>
          <v-pagination
            small
            color="#766BC0"
            v-model="page"
            :length="Pagelength"
          >
          </v-pagination>
        </v-flex>
      </v-layout>
      <v-snackbar
        :timeout="3000"
        :value="true"
        centered
        color="red"
        v-model="showsnackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
    </v-flex>
  </v-layout>
</template>    
<script>
import axios from "axios";
export default {
  data() {
    return {
      valid: true,
      valid1: true,
      student: [],
      page: 1,
      Pagelength: 0,
      dialoge: false,
      editdialogue: false,
      adddialogue: false,
      adddialogue2: false,
      hostelName: null,
      hostel: null,
      myRoom: null,
      curId: null,
      curItem: null,
      showsnackbar: false,
      msg: null,
      appLoading: false,
      ServerError: false,
      Type1: null,
      type: null,
      roomType:null,
      Type: ["Non-AC", "AC"],
      // roomType: 'Non-AC',
      quantityType: null,
      productType: "",
      keyword: null,
      assetType: null,
      floorName: null,
      floorId: null,
      rangeFrom: null,
      rangeTo: null,
      floorNo: null,
      roomNo: null,
      roomCount: [],
      totalCapacity: null,
      floorList: [],
      HostelList: [],
      RoomList: ["Non-AC", "AC"],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  mounted() {
    this.gethostel();
    this.getData();
    this.getFloor();
  },
  watch: {
    hostelName() {
      this.getData();
    },
    keyword() {
      this.getData();
    },
    page() {
      // this.getData();
      this.getData();
    },
    getroomType(){
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/hostelroom/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
          mainHostelId: this.hostel,
          page: this.page,
          limit: 30,
          roomtype: this.roomType,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.student = response.data.data;
            this.roomCount = response.data.stats;
            this.Pagelength = Math.ceil(
              response.data.totalLength / response.data.limit
            );
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    gethostel() {
      axios({
        method: "get",
        url: "/main/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
            this.hostelName = this.HostelList[0]._id;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    
    getFloor() {
      axios({
        method: "get",
        url: "/floor/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params:{
          hostelName:this.hostel
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.floorList = response.data.data;
            this.type = this.floorList[0]._id;
            // console.log(" this.hostelName", this.hostelName);
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    //delete button
    deleteCategory() {
      axios({
        url: "/hostelroom/remove",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //add button

    addCategory() {
      if (this.$refs.form.validate()) {
        axios({
          url: "/hostelroom/add",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            mainHostelId: this.hostel,
            type: this.type,
            floorId: this.floorId,
            roomNo: this.roomNo,
            totalCapacity: this.totalCapacity,
          },
        })
          .then((response) => {
            this.adddialogue = false;
            this.appLoading = false;
            if (response.data.status) {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.name = null;
              this.RoomNo = null;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }

            this.$refs.form1.resetValidation();
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    addCategory2() {
      if (this.$refs.form.validate()) {
        axios({
          url: "/hostelroom/muliple/add",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            hostelName: this.hostel,
            type: this.Type1,
            totalCapacity: this.totalCapacity,
            floorId: this.floorId,
            roomFrom: this.rangeFrom,
            roomTo: this.rangeTo,
          },
        })
          .then((response) => {
            this.adddialogue2 = false;
            this.appLoading = false;
            if (response.data.status) {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.hostel = null;
              this.Type1 = null;
              this.floorId = null;
              this.rangeTo = null;
              this.rangeFrom = null;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }

            this.$refs.form1.resetValidation();
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    //edit button
    editCategory() {
      axios({
        url: "/hostelroom/edit",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curItem._id,
          hostelName: this.curItem.hostelName,
          type: this.curItem.type,
          floorId: this.curItem.floorId,
          roomNo: this.curItem.roomNo,
          totalCapacity: this.curItem.totalCapacity,
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>